<template>
  <div class="VariantSelector type-sm font-medium w-full">
    <!--    <DebugOutput v-if="productItem.partNo === '986733'">{{ parametrics }}</DebugOutput>-->
    <!-- show as dropdowns - only in PLP list view when multi options -->
    <div v-if="design === 'list' && multipleParametrics" class="flex gap-x-12 w-full">
      <div
        v-for="(parametric) in parametrics"
        :key="`variant-parametric-${parametric.code}`"
        class="basis-1/2 grow-0 relative"
      >
        <div class="absolute border border-darkest w-full z-above bg-lightest">
          <div class="flex flex-col">
            <div
              v-for="value in parametric.values.filter(v => v)"
              :key="`variant-parametric-value${value.code}`"
            >
              <button
                v-show="expanded === `variant-parametric-${parametric.code}` || isSelected(parametric, value)"
                class="btn btn--secondary w-full !justify-start"
                :class="[ getOptionClasses(parametric, value), {
                }]"
                @click="clickMulti(parametric, value, `variant-parametric-${parametric.code}`)"
              >
                {{ value.uom ? value.name + ' ' + value.uom : value.name }}
              </button>
            </div>
          </div>
          <div class="absolute right-8 top-12">
            <img
              src="/icons/chevron-up.svg"
              class="w-16 h-16 transition-all"
            >
          </div>
        </div>
        &nbsp;
      </div>
    </div>
    <!-- show as buttons - everywhere else -->
    <div v-else>
      <div
        v-for="(parametric) in parametrics"
        :key="'variant-parametric-' + parametric.code"
      >
        <div
          class="mobOnly:px-12"
        >
          <div v-if="design === 'standard'" class="mb-4">{{ parametric.name }}</div>
          <div
            class="flex "
            :class="{
              'flex-wrap': design !== 'list',
              'gap-x-8': design === 'list'
            }"
          >
            <div
              v-for="value in parametric.values.filter(v => v)"
              :key="'variant-parametric-value' + value.code"
              class="mr-8 mb-8 last-of-type:mr-0"
            >
              <button
                class="btn btn--secondary"
                :class="[ getOptionClasses(parametric, value), {
                  '!px-12': design === 'list',
                }]"
                @click="selectParametric(parametric, value)"
              >
                {{ value.uom ? value.name + ' ' + value.uom : value.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
/**
 * This component is used on ProductPage, Quickbuy and on ProductList (in list view only)
 * Check all combinations when changing
 */
import { ProductModel } from '~/models/product';
import { ProductVariantModel } from '~/models/productVariant';
import { NorceParametricCodes } from '~/constants/norceCodes';
import { useUiStore } from '~/store/ui';
import { ParametricData, ProductVariantParametrics, ProductVariantParametricValue } from '~/constants/types/algolia';

const uiStore = useUiStore();

interface Props {
  productItem: ProductModel,
  activeVariant: ProductVariantModel,
  design: 'standard' |'quickbuy' | 'list',
}

const props = withDefaults(defineProps<Props>(), {
  design: 'standard',
});

type SelectedType = { parametric: string, value: string, type: 'list' | 'multiple' | 'integer' | 'text' | 'decimal' }[]

const getParametricDataValue = (parametricData: ParametricData): string => {
  switch (parametricData.type) {
    case 'list':
      return parametricData.listValue?.code ?? '';
    case 'multiple':
      if (parametricData.multipleValues?.length) {
        return parametricData.multipleValues[0]?.code;
      }
      return '';
    default:
      return parametricData.value +'';
  }
};

const selected = computed(()=> {
  const output = [] as SelectedType;
  props.activeVariant.definingParametrics.forEach((vp)=> {
    const selectedOption = getParametricDataValue(vp);
    output.push({
      parametric: vp.code,
      value: selectedOption,
      type: vp.type,
    });
  });
  return output;
});

const isSelected = (parametric: ProductVariantParametrics, value: ProductVariantParametricValue) => {
  const thisSelected = selected.value.find((f) => f.parametric === parametric.code );
  if (thisSelected) {
    if (thisSelected.type === 'multiple' || thisSelected.type === 'list') {
      return  thisSelected.value === value.code;
    } else {
      return thisSelected.value === value.name;
    }
  }
  return false;
};

const emit = defineEmits<{
  (e: 'updateActiveVariant', obj: ProductVariantModel): void;
}>();

const parametrics = computed(() => {
  const clone = JSON.parse(JSON.stringify(props.productItem.allVariantDefiningParametrics));
  return clone.filter((p) => p.code !== NorceParametricCodes.SalesRepNo).map((p) => {
    try {
      p.values = p.values.sort((a,b) => parseInt(a?.name) - parseInt(b?.name));
    } catch (e) {
      console.warn('Couldn\'t sort parametric values', e);
    }
    return p;
  }).filter(f => f.values.length > 0);
});

const multipleParametrics = computed(()=> {
  return parametrics.value.length > 1;
});

const getOptionClasses = (
  parametricCode: ProductVariantParametrics,
  valueCode: ProductVariantParametricValue
): string => {
  const getVariant = getVariantFromSelected(
    parametricCode,
    valueCode,
    false,
    true
  );
  let output = [] as string[];

  if (isSelected(parametricCode, valueCode)) {
    output.push('selected');
  }
  if (valueCode.code?.toLowerCase() === NorceParametricCodes.Volume.toLowerCase()) {
    output.push('!normal-case');
  }

  if (getVariant) {
    // Product is not coming back
    if (!getVariant.canAddToCart) {
      // temporary gone, but can signup for notification
      output.push('notAvaliable');
    }
  } else {
    // Product doesn't exist
    output.push('nonExisting pointer-events-none');
  }
  return output.join(' ');
};

const selectParametric = (
  parametricCode: ProductVariantParametrics,
  valueCode: ProductVariantParametricValue
) => {
  const getVariant = getVariantFromSelected(parametricCode, valueCode);
  if (getVariant) {
    emit('updateActiveVariant', getVariant);
  }
};

const getVariantFromSelected = (
  parametricCode: ProductVariantParametrics,
  valueCode: ProductVariantParametricValue,
  findAlternative = true,
  showUserWarnings = true
): ProductVariantModel | null => {
  const thisSelected = JSON.parse(JSON.stringify(selected.value)) as SelectedType;
  const changeIndex = thisSelected.findIndex((f) => {
    return f.parametric === parametricCode.code;
  });
  if (changeIndex > -1) {
    if (thisSelected[changeIndex].type === 'list' || thisSelected[changeIndex].type === 'multiple') {
      thisSelected[changeIndex].value = valueCode.code;
    } else {
      thisSelected[changeIndex].value = valueCode.name;
    }

    // Check if the new combo exists
    const existsIndex = props.productItem.variants.findIndex((variant) => {
      let result = true;
      thisSelected.forEach((ts) => {
        const findParametric = variant.definingParametrics.find((vp) => vp.code === ts.parametric);

        if (!findParametric) {
          result =  false;
        } else {
          const findParametricValue = getParametricDataValue(findParametric);
          if (findParametricValue !== ts.value) {
            result = false;
          }
        }
      });
      return result;
    });

    if (existsIndex > -1) {
      return props.productItem.variants[existsIndex];
      //emit('updateActiveVariant', props.productItem.variants[existsIndex]);
    } else if (findAlternative) {
      // changing to a variant that exists
      const fallbackIndex = props.productItem.variants.findIndex((variant) => {
        const findParametric = variant.definingParametrics.find((vp) => vp.code === parametricCode.code);
        if (!findParametric) {
          return false;
        } else {
          const findParametricValue = findParametric.multipleValues?.length ? findParametric.multipleValues[0] : findParametric.listValue;
          return findParametricValue?.code === valueCode.code;
        }
      });

      if (fallbackIndex > -1) {
        console.log('Used fallback variant');
        return props.productItem.variants[fallbackIndex];
        //emit('updateActiveVariant', props.productItem.variants[fallbackIndex]);
      } else {
        console.warn('couldn\'t find any matching variant');
        if (showUserWarnings) {uiStore.setTemporaryError('couldn\'t find any matching variant');}
      }

    }

  } else {
    if (showUserWarnings) {uiStore.setTemporaryError('Problem finding selected variant');}
  }
  return null;
};

// expand multiselect
const expanded = ref('');

const clickMulti = (
  parametricCode: ProductVariantParametrics,
  valueCode: ProductVariantParametricValue,
  expand: string
) => {
  if (expanded.value === expand) {
    expanded.value = '';
  } else {
    expanded.value = expand;
  }
  selectParametric(parametricCode, valueCode);
};

</script>

<style lang="postcss" scoped>

.expired {
  @apply line-through opacity-40 pointer-events-none;
}
.nonExisting {
  @apply opacity-30 cursor-not-allowed;
}
</style>
